import { fresponsive } from "library/fullyResponsive"
import type { ReactNode } from "react"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import { useEffect, useRef } from "react"
import gsap from "gsap"

const Group = ({ children }: { children: ReactNode }) => {

	const magnetic = useRef<SVGGElement | null>(null);

	useEffect( () => {
		if (!magnetic.current) return
	
		const xTo = gsap.quickTo(magnetic.current, "x", {duration: 1, ease: "elastic.out(1, 0.3)"})
		const yTo = gsap.quickTo(magnetic.current, "y", {duration: 1, ease: "elastic.out(1, 0.3)"})

		const mouseMove = (e) => {
			if (!magnetic.current) return

			const { clientX, clientY } = e;
			const {height, width, left, top} = magnetic.current.getBoundingClientRect();
			const x = clientX - (left + width/4)
			const y = clientY - (top + height/4)

			xTo(x);
			yTo(y)
		}

		const mouseLeave = (e) => {
			gsap.to(magnetic.current, {x: 0, duration: 1})
			gsap.to(magnetic.current, {y: 0, duration: 1})
			xTo(0);
			yTo(0)
		}

		magnetic.current.addEventListener("mousemove", mouseMove)
		magnetic.current.addEventListener("mouseleave", mouseLeave)

		return () => {
			if (!magnetic.current) return

			magnetic.current.removeEventListener("mousemove", mouseMove)
			magnetic.current.removeEventListener("mouseleave", mouseLeave)
		}
	}, [])

	return <g ref={magnetic}>{children}</g>
}

export default function Name() {
	return (
		<Wrapper
			width="1485"
			height="898"
			viewBox="0 0 1501 898"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g filter="url(#filter0_f_46_9534)">
	
			</g>

			<Group>
				<path filter="url(#filter0_f_46_9534)" d="M67.5462 440.378C58.8213 442.75 52.7067 442.084 49.2024 438.38C45.7845 434.654 45.2939 428.205 47.7305 419.034L83.9006 282.9C86.3602 273.643 90.6207 265.903 96.6821 259.68C102.853 253.348 110.301 248.996 119.026 246.625C127.751 244.254 133.822 244.931 137.24 248.658C140.767 252.275 141.301 258.712 138.841 267.969L125.911 316.635L126.948 316.353L201.625 235.735C211.261 225.411 220.485 219.051 229.296 216.657C236.466 214.708 241.944 215.507 245.731 219.053C249.517 222.599 250.468 227.92 248.583 235.014C247.479 239.167 245.659 243.153 243.121 246.974C240.67 250.77 236.949 255.273 231.958 260.483L182.379 312.245L209.498 351.351C212.709 355.856 214.765 360.034 215.666 363.882C216.653 367.708 216.629 371.567 215.595 375.46C213.595 382.987 209.369 389.996 202.916 396.485C196.549 402.951 189.608 407.206 182.093 409.248C176.218 410.845 171.4 411.03 167.638 409.805C163.876 408.58 160.508 405.723 157.533 401.233L129.721 359.306L108.912 380.614L102.671 404.103C100.235 413.274 95.931 421.025 89.7602 427.358C83.6757 433.667 76.2711 438.007 67.5462 440.378Z" fill="#323232"/>
				<path d="M36.1523 344.537L92.4096 152.612L145.151 139.71L122.407 217.304L125.063 216.655L206.24 124.765L267.709 109.728L177.015 211.253L212.97 301.282L149.983 316.69L127.444 255.393L103.398 282.154L88.894 331.635L36.1523 344.537Z" fill="#FE4420" stroke="#FE4420" strokeWidth={8}/>
			</Group>

			<Group>
				<path filter="url(#filter0_f_46_9534)" d="M304.578 503.324C296.868 506.172 291.156 506.246 287.443 503.546C283.82 500.737 282.643 495.478 283.914 487.769C284.721 482.871 286.806 476.747 290.168 469.397L344.863 348.298C350.185 336.453 356.341 326.94 363.332 319.758C370.335 312.496 378.685 307.074 388.38 303.492C398.762 299.657 406.686 298.993 412.151 301.498C417.693 303.975 421.448 309.903 423.415 319.281L443.036 412.929C444.154 418.322 444.29 423.588 443.443 428.727C442.252 435.954 438.982 442.742 433.634 449.091C428.286 455.44 422.101 459.912 415.078 462.506C407.902 465.157 402.595 465.383 399.158 463.184C395.797 460.956 393.781 456.008 393.11 448.338L392.193 437.592L338.374 457.472L333.705 469.49C329.972 479.088 325.803 486.548 321.198 491.868C316.683 497.08 311.143 500.899 304.578 503.324ZM353.808 416.027L388.275 403.296L381.502 346.527L380.586 346.866L353.808 416.027Z" fill="#323232"/>
				<path d="M303.726 407.149C296.456 410.009 291.033 410.205 287.457 407.735C283.968 405.155 282.743 400.24 283.782 392.99C284.44 388.398 286.306 382.609 289.38 375.623L343.602 248.591C348.354 237.372 353.937 228.334 360.35 221.477C366.776 214.541 374.494 209.299 383.506 205.754C393.123 201.97 400.519 201.226 405.692 203.524C410.952 205.711 414.602 211.154 416.643 219.853L439.097 316.716C440.31 321.712 440.57 326.626 439.878 331.46C438.896 338.307 435.93 344.795 430.978 350.924C426.038 356.973 420.274 361.293 413.685 363.885C407.021 366.507 402.044 366.831 398.754 364.857C395.54 362.853 393.52 358.251 392.694 351.05L390.831 337.873L336.419 359.281L330.435 374.862C327.136 383.914 323.372 390.982 319.142 396.067C314.999 401.041 309.86 404.736 303.726 407.149ZM350.379 319.468L386.388 305.3L377.741 247.473L376.832 247.831L350.379 319.468Z" fill="#FE4420" stroke="#FE4420" strokeWidth={8}/>
			</Group>

			<Group>
				<path filter="url(#filter0_f_46_9534)" d="M525.987 398.962C517.734 401.013 511.961 400.285 508.67 396.777C505.466 393.247 505.104 387.089 507.584 378.301L547.233 237.83C549.568 229.56 553.558 222.72 559.205 217.311C564.938 211.881 572.018 208.118 580.445 206.024C585.484 204.771 589.661 204.855 592.976 206.274C596.403 207.585 599.373 210.372 601.885 214.634L641.644 283.698L642.686 283.439L663.222 210.684C665.702 201.896 669.901 194.604 675.819 188.808C681.736 183.011 688.778 179.098 696.945 177.068C705.198 175.017 710.926 175.756 714.131 179.285C717.422 182.793 717.827 188.941 715.347 197.728L675.698 338.199C673.388 346.384 669.441 353.213 663.857 358.686C658.297 364.073 651.391 367.792 643.138 369.844C637.838 371.161 633.519 371.153 630.179 369.82C626.864 368.401 623.993 365.55 621.567 361.266L581.725 290.78L580.683 291.04L559.709 365.346C557.229 374.133 553.03 381.425 547.112 387.222C541.282 392.997 534.24 396.91 525.987 398.962Z" fill="#323232"/>
				<path d="M728.392 115.708C725.822 116.107 722.823 116.703 719.394 117.495C716.216 118.108 712.587 119.154 708.506 120.633C704.425 122.112 701.979 123.749 701.168 125.543C698.378 130.961 691.327 145.996 680.015 170.648L636.578 268.462L627.22 269.346L605.02 210.301C590.398 170.921 582.513 150.426 581.363 148.816C574.918 162.701 569.704 174.048 565.723 182.858C560.709 193.952 556.094 204.54 551.879 214.622C547.843 224.686 542.433 237.224 535.649 252.233C534.322 255.17 532.847 258.433 531.225 262.022C528.202 268.711 529.81 271.761 536.048 271.171C546.208 270.211 551.303 269.885 551.334 270.195L549.343 274.6C540.831 274.936 531.049 275.626 519.998 276.671C507.699 277.834 497.125 279.146 488.274 280.607L490.044 276.691C490.044 276.691 490.578 276.641 491.648 276.54C506.264 275.158 514.865 272.549 517.452 268.712C540.316 220.01 553.628 191.498 557.388 183.178C563.066 170.615 569.45 156.112 576.54 139.667C576.995 137.906 573.912 135.855 567.292 133.514L557.252 130.246L559.242 125.841L608.594 121.41C613.064 133.794 620.842 153.206 631.928 179.645L649.091 219.254L691.275 126.479C692.129 124.211 690.712 122.627 687.024 121.727C683.41 120.663 678.662 120.409 672.78 120.965C671.532 121.083 670.196 121.209 668.77 121.344L670.779 115.766C677.896 115.874 686.98 115.405 698.031 114.361C708.191 113.4 719.122 112.055 730.825 110.324L728.392 115.708Z" fill="#FE4420" stroke="#FE4420" strokeWidth={8}/>
			</Group>

			<Group>
				<path filter="url(#filter0_f_46_9534)" d="M902.775 458.189C894.561 457.913 887.785 455.37 882.448 450.562C877.193 445.756 873.85 439.1 872.421 430.593L867.162 399.293L803.285 318.018C798.749 312.2 795.975 306.281 794.964 300.262C793.764 293.119 795.26 287.344 799.453 282.936C803.632 278.449 809.545 276.334 817.19 276.591C822.883 276.783 827.688 278.182 831.603 280.788C835.586 283.316 839.859 287.809 844.422 294.267L885.038 351.895L886.014 351.928L907.72 296.28C910.057 290.453 912.84 286.318 916.068 283.873C919.283 281.348 923.494 280.173 928.699 280.348C936.1 280.598 942.585 283.09 948.152 287.827C953.801 292.566 957.205 298.386 958.364 305.288C959.389 311.387 958.557 317.265 955.868 322.92L918.891 401.036L924.149 432.336C925.579 440.843 924.407 447.347 920.634 451.848C916.943 456.352 910.99 458.466 902.775 458.189Z" fill="#323232"/>
				<path d="M785.308 178.548H840.35L890.546 250.938H891.966L912.793 178.548H967.836L925.854 300.705L937.22 356.737H888.215L876.849 300.705L785.308 178.548Z" fill="#FE4420" stroke="#FE4420" strokeWidth={8}/>
			</Group>

			<Group>
				<path filter="url(#filter0_f_46_9534)" d="M1147.91 375.45C1130.76 369.529 1114.72 360.914 1099.81 349.605C1084.95 338.246 1071.96 324.932 1060.85 309.665C1049.8 294.346 1041.33 277.801 1035.46 260.028C1029.56 242.178 1027.91 227.982 1030.49 217.44C1033.05 206.821 1039.45 200.198 1049.69 197.572C1059.91 194.867 1073.59 196.476 1090.75 202.396C1107.9 208.317 1123.91 216.957 1138.77 228.317C1153.61 239.599 1166.59 252.912 1177.73 268.257C1188.84 283.525 1197.34 300.084 1203.24 317.934C1209.11 335.707 1210.77 349.903 1208.21 360.521C1205.62 371.063 1199.22 377.686 1189.01 380.39C1178.77 383.017 1165.07 381.37 1147.91 375.45ZM1134.97 336.272C1141.82 338.635 1147.14 338.761 1150.94 336.65C1154.82 334.565 1156.97 330.446 1157.39 324.292C1157.89 318.166 1156.56 310.311 1153.39 300.729C1150.2 291.07 1146.01 282.195 1140.81 274.103C1135.62 266.012 1129.84 259.158 1123.49 253.542C1117.14 247.926 1110.54 243.937 1103.69 241.574C1096.92 239.237 1091.6 239.112 1087.72 241.196C1083.92 243.308 1081.77 247.427 1081.27 253.554C1080.77 259.68 1082.12 267.573 1085.31 277.232C1088.47 286.814 1092.62 295.638 1097.73 303.703C1102.93 311.794 1108.7 318.648 1115.06 324.264C1121.49 329.906 1128.12 333.909 1134.97 336.272Z" fill="#323232"/>
				<path d="M1137.19 279.511C1120.53 272.331 1105.18 262.549 1091.15 250.162C1077.17 237.73 1065.21 223.488 1055.27 207.436C1045.38 191.338 1038.17 174.209 1033.63 156.049C1029.08 137.81 1028.48 123.531 1031.84 113.21C1035.18 102.811 1042.06 96.6818 1052.47 94.8235C1062.86 92.8862 1076.38 95.5073 1093.05 102.687C1109.72 109.866 1125.04 119.672 1139.02 132.105C1152.97 144.459 1164.93 158.701 1174.9 174.831C1184.84 190.882 1192.09 208.028 1196.64 226.267C1201.18 244.427 1201.77 258.706 1198.43 269.106C1195.07 279.426 1188.2 285.555 1177.81 287.492C1167.4 289.351 1153.86 286.69 1137.19 279.511ZM1127.2 239.48C1133.85 242.345 1139.15 242.866 1143.1 241.043C1147.12 239.252 1149.57 235.305 1150.45 229.199C1151.4 223.127 1150.65 215.195 1148.21 205.404C1145.75 195.535 1142.22 186.372 1137.65 177.917C1133.07 169.462 1127.82 162.198 1121.9 156.125C1115.98 150.052 1109.7 145.583 1103.04 142.718C1096.47 139.885 1091.17 139.364 1087.15 141.154C1083.2 142.977 1080.75 146.925 1079.8 152.998C1078.84 159.071 1079.6 167.042 1082.06 176.912C1084.51 186.702 1087.98 195.809 1092.49 204.232C1097.07 212.688 1102.31 219.952 1108.23 226.024C1114.23 232.129 1120.55 236.614 1127.2 239.48Z" fill="#FE4420" stroke="#FE4420" strokeWidth={8}/>
			</Group>

			<Group>
				<path filter="url(#filter0_f_46_9534)" d="M1321.88 439.585C1314.77 436.558 1308.89 432.028 1304.26 425.996C1299.69 419.995 1296.78 412.893 1295.52 404.689L1275.32 273.545C1274.13 265.823 1275.14 260.764 1278.34 258.368C1281.61 256.003 1286.88 256.366 1294.15 259.457C1298.49 261.305 1302.56 264.088 1306.35 267.806C1310.2 271.476 1314.23 276.496 1318.43 282.868L1385.56 385.507L1386.46 385.89L1376 317.965C1374.74 309.761 1375.75 304.331 1379.05 301.677C1382.35 299.023 1387.52 299.193 1394.56 302.188C1401.68 305.215 1407.52 309.729 1412.08 315.73C1416.72 321.762 1419.67 328.88 1420.93 337.084L1441.12 468.229C1442.3 475.87 1441.33 480.944 1438.22 483.453C1435.1 485.882 1429.98 485.582 1422.86 482.555C1418.3 480.611 1414.12 477.821 1410.34 474.183C1406.55 470.465 1402.59 465.436 1398.47 459.096L1330.66 354.818L1329.76 354.436L1340.45 423.808C1341.71 432.012 1340.69 437.442 1337.39 440.096C1334.17 442.782 1329 442.612 1321.88 439.585Z" fill="#323232"/>
				<path d="M1412.69 226.894C1410.62 226.155 1408.28 225.447 1405.66 224.773C1403.17 224.001 1400.51 223.536 1397.69 223.376C1394.87 223.217 1393.59 224.022 1393.85 225.793C1394.35 230.976 1396.35 245.688 1399.84 269.928L1415 366.793L1407.16 363.417L1354.87 281.976C1320.16 227.746 1301.85 199.392 1299.94 196.914C1301.84 210.531 1303.44 221.686 1304.75 230.377C1306.39 241.323 1308.11 251.849 1309.9 261.957C1311.85 272.13 1313.93 284.62 1316.15 299.429C1316.59 302.326 1317.07 305.545 1317.6 309.086C1318.59 315.685 1321.7 320.111 1326.94 322.362C1335.46 326.027 1339.82 328.053 1340.02 328.44L1340.67 332.786C1333.27 329.151 1324.94 325.341 1315.67 321.353C1305.35 316.916 1296.65 313.475 1289.57 311.032L1288.99 307.169C1288.99 307.169 1289.44 307.362 1290.34 307.748C1302.6 313.021 1308.82 313.968 1309.02 310.588C1302.57 262.943 1298.73 235.017 1297.5 226.808C1295.64 214.414 1293.34 200.024 1290.6 183.638C1290.04 181.739 1286.16 177.814 1278.97 171.861L1268.23 163.174L1267.58 158.828L1309.1 176.917C1319.91 193.91 1337.52 220.912 1361.96 257.923L1399.06 313.632L1385.55 222.223C1385.07 219.906 1382.93 217.331 1379.15 214.5C1375.35 211.508 1370.98 208.951 1366.04 206.828C1365 206.378 1363.87 205.896 1362.68 205.381L1361.4 199.635C1367.8 203.141 1375.63 206.888 1384.9 210.875C1393.42 214.541 1402.42 218.11 1411.89 221.582L1412.69 226.894Z" fill="#FE4420" stroke="#FE4420" strokeWidth={8}/>
			</Group>

			<Group>
				<path filter="url(#filter0_f_46_9534)" d="M264.422 764.802C257.372 761.286 251.044 756.428 245.437 750.226C239.91 744.064 235.597 737.071 232.498 729.248L182.108 602.036C177.69 590.884 177.138 583.729 180.453 580.573C183.767 577.417 190.751 578.496 201.406 583.809C210.378 588.283 218.276 593.857 225.098 600.532C232.001 607.247 239.2 616.413 246.695 628.032L308.929 724.283L309.891 724.762L307.619 658.412C307.334 650.389 308.987 645.636 312.579 644.155C316.251 642.713 322.573 644.23 331.545 648.704C342.2 654.017 351.558 661.088 359.62 669.916C367.681 678.745 373.921 688.735 378.338 699.888L428.729 827.1C431.828 834.923 432.296 839.998 430.132 842.325C427.968 844.652 423.321 844.038 416.191 840.483C409.141 836.967 402.813 832.109 397.206 825.907C391.68 819.745 387.367 812.752 384.268 804.929L351.383 721.91L350.422 721.431L351.687 794.492C351.817 801.637 347.556 803.052 338.904 798.737C329.932 794.263 322.056 786.796 315.277 776.336L245.036 668.88L244.075 668.4L276.96 751.419C280.059 759.242 280.526 764.317 278.362 766.644C276.198 768.971 271.551 768.357 264.422 764.802Z" fill="#323232"/>
				<path d="M177.574 455.414L234.365 492.041L303.307 626.506L305.277 627.777L314.464 543.701L371.255 580.329L424.004 773.247L379.359 744.453L348.863 632.922L347.55 632.075L340.211 717.963L313.293 700.602L245.58 565.688L244.267 564.842L274.969 677.126L230.324 648.332L177.574 455.414Z" fill="#FE4420" stroke="#FE4420" strokeWidth={8}/>
			</Group>

			<Group>
				<path filter="url(#filter0_f_46_9534)" d="M697.518 823.505C705.736 823.606 711.493 821.607 714.786 817.508C717.972 813.331 718.298 807.548 715.763 800.158C714.152 795.463 711.056 789.982 706.476 783.717L631.718 680.239C624.434 670.108 616.691 662.654 608.488 657.878C600.26 653.025 590.978 650.534 580.644 650.408C569.577 650.272 561.499 652.472 556.411 657.009C551.242 661.545 548.432 668.946 547.984 679.212L543.524 781.718C543.279 787.617 544.002 793.03 545.692 797.957C548.069 804.885 552.464 810.611 558.878 815.135C565.292 819.66 572.242 821.968 579.729 822.06C587.378 822.154 592.751 820.457 595.85 816.969C598.866 813.48 600.087 807.707 599.511 799.652L598.681 788.374L656.05 789.078L662.706 799.622C668.025 808.043 673.435 814.126 678.934 817.873C684.325 821.542 690.52 823.419 697.518 823.505ZM633.768 752.471L597.027 752.02L594.58 691.741L595.557 691.753L633.768 752.471Z" fill="#323232"/>
				<path d="M728.432 749.318H657.387L655.664 744.49C658.105 744.49 660.14 744.49 661.767 744.49C663.34 744.336 664.587 744.183 665.509 744.03C666.539 743.723 667.434 743.493 668.193 743.34C668.897 743.033 669.574 742.65 670.224 742.19C671.253 741.424 671.901 740.504 672.169 739.431C672.545 738.204 672.515 736.978 672.077 735.752C670.545 731.46 666.622 725.481 660.306 717.816L639.644 692.753H566.89L564.863 723.335C564.814 725.021 564.873 726.784 565.041 728.624C565.317 730.31 565.756 731.996 566.358 733.682C567.616 737.208 570.038 739.891 573.624 741.73C577.21 743.57 582.421 744.49 589.257 744.49L590.98 749.318H535.56L533.837 744.49C537.201 744.336 539.939 743.8 542.052 742.88C544.165 741.96 545.923 740.044 547.325 737.131C548.673 734.066 549.745 729.773 550.543 724.255C551.449 718.583 552.376 710.918 553.324 701.261L565.238 572.494H570.609L693.357 721.495C697.386 726.401 700.734 730.31 703.401 733.222C706.231 736.135 708.842 738.434 711.234 740.121C713.571 741.654 715.907 742.727 718.242 743.34C720.739 743.953 723.561 744.336 726.709 744.49L728.432 749.318ZM632.208 682.176L574.136 609.744L567.755 682.176H632.208Z" fill="#FE4420" stroke="#FE4420" strokeWidth={8}/>
			</Group>

			<Group>
				<path filter="url(#filter0_f_46_9534)" d="M803.546 838.279C795.896 837.161 791.18 834.478 789.397 830.23C787.695 825.994 789.191 820.453 793.885 813.607L868.921 704.171C873.339 697.727 878.752 693.128 885.16 690.372C891.648 687.628 898.798 686.826 906.609 687.967C911.279 688.65 914.822 690.201 917.238 692.622C919.78 694.987 921.499 698.487 922.396 703.123L936.108 777.897L937.075 778.038L975.939 721.357C980.633 714.511 986.357 709.588 993.109 706.587C999.862 703.586 1007.02 702.638 1014.59 703.744C1022.24 704.862 1026.92 707.539 1028.62 711.775C1030.4 716.023 1028.95 721.57 1024.25 728.416L949.218 837.853C944.846 844.229 939.473 848.834 933.1 851.669C926.773 854.437 919.784 855.262 912.134 854.144C907.222 853.426 903.536 851.891 901.074 849.537C898.658 847.117 897.042 843.595 896.226 838.971L882.859 762.918L881.893 762.777L842.2 820.666C837.506 827.512 831.782 832.436 825.029 835.436C818.357 838.449 811.196 839.396 803.546 838.279Z" fill="#323232"/>
				<path d="M818.773 759.737C811.449 759.737 806.666 757.874 804.424 754.148C802.218 750.348 802.836 744.941 806.276 737.927L865.67 616.82C868.931 610.171 873.415 605.093 879.123 601.585C884.912 598.078 891.55 596.325 899.037 596.325C903.594 596.325 907.197 597.274 909.846 599.174C912.577 601.074 914.634 604.179 916.02 608.49L941.422 684.881H942.398L975.132 618.135C978.572 611.12 983.241 605.75 989.137 602.024C995.07 598.224 1001.66 596.325 1008.9 596.325C1016.22 596.325 1020.99 598.224 1023.19 602.024C1025.44 605.75 1024.84 611.12 1021.4 618.135L962.003 739.242C958.742 745.891 954.298 750.969 948.672 754.477C943.046 757.984 936.571 759.737 929.246 759.737C924.445 759.737 920.72 758.788 918.071 756.888C915.503 754.988 913.527 751.883 912.141 747.572L886.964 669.975H885.866L852.54 737.927C849.1 744.941 844.414 750.348 838.482 754.148C832.667 757.874 826.097 759.737 818.773 759.737Z" fill="#FE4420" stroke="#FE4420" strokeWidth={8}/>
			</Group>

			<Group>
				
				<path filter="url(#filter0_f_46_9534)" d="M1151.04 784.219C1145.09 789.166 1140.89 790.863 1138.43 789.312C1136.03 787.708 1135.63 782.835 1137.24 774.691L1162.98 644.52C1164.49 636.856 1167.24 629.7 1171.22 623.053C1175.26 616.353 1180.31 610.479 1186.38 605.428C1190.01 602.408 1193.04 600.818 1195.47 600.659C1197.99 600.367 1200.2 601.459 1202.11 603.934L1232.44 644.42L1233.19 643.795L1246.52 576.374C1248.13 568.231 1251.01 560.624 1255.18 553.553C1259.35 546.483 1264.37 540.5 1270.25 535.606C1276.19 530.66 1280.36 528.988 1282.76 530.592C1285.23 532.143 1285.65 536.991 1284.04 545.134L1258.31 675.305C1256.81 682.89 1254.1 690.019 1250.16 696.694C1246.25 703.29 1241.32 709.06 1235.38 714.007C1231.56 717.183 1228.43 718.891 1225.98 719.13C1223.54 719.29 1221.4 718.106 1219.55 715.579L1189.13 673.968L1188.38 674.593L1174.77 743.451C1173.16 751.594 1170.27 759.201 1166.11 766.272C1162 773.29 1156.98 779.273 1151.04 784.219Z" fill="#323232"/>
				<path d="M1295.07 425.791L1247.35 601.235L1213.91 625.297L1185.66 571.496L1184.8 572.119L1159.73 664.295L1119.66 693.129L1167.38 517.685L1201.39 493.208L1228.87 547.288L1230.03 546.458L1255.01 454.625L1295.07 425.791Z" fill="#FE4420" stroke="#FE4420" strokeWidth={8}/>
			</Group>

			<defs>
				<filter id="filter0_f_46_9534" x="0.0742188" y="130.59" width="1484.69" height="766.995" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
					<feFlood flood-opacity="0" result="BackgroundImageFix"/>
					<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
					<feGaussianBlur stdDeviation="20" result="effect1_foregroundBlur_46_9534"/>
				</filter>
			</defs>
		</Wrapper>
	)
}

const Wrapper = styled.svg`
  height: auto;

  ${fresponsive(css`
		width: 1388px;
		height: 838px;
	`)}
`
