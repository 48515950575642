import { ReactComponent as BlobSVG } from "images/global/blob.svg"
import { fresponsive } from "library/fullyResponsive"
import styled, { css } from "styled-components"
import Name from "./Name"

export default function Footer() {
	return (
		<Wrapper>
			<Name />
			<Blob1 />
			<Blob2 />
			<Blob3 />
			<Blob4 />
			<Blob5 />
			<Blob6 />
			<Blob7 />
			<Blob8 />
		</Wrapper>
	)
}

const Wrapper = styled.footer`
	display: grid;
	place-items: center;
	position: relative;

  ${fresponsive(css`
		padding: 100px 170px;
	`)}
`

const Blob = styled(BlobSVG)`
	height: auto;
	position: absolute;

	${fresponsive(css`
		width: 40px;
	`)}
`

const Blob1 = styled(Blob)`
	${fresponsive(css`
		top: 40px;
		left: 40px;
	`)}
`

const Blob2 = styled(Blob)`
	${fresponsive(css`
		top: 40px;
		right: 40px;
	`)}
`

const Blob3 = styled(Blob)`
	left: 50%;
	transform: translateX(-50%);

	${fresponsive(css`
		top: 40px;
	`)}
`

const Blob4 = styled(Blob)`
	${fresponsive(css`
		top: 450px;
		left: 40px;
	`)}
`

const Blob5 = styled(Blob)`
	${fresponsive(css`
		top: 450px;
		left: 40px;
	`)}
`

const Blob6 = styled(Blob)`
	${fresponsive(css`
		top: 450px;
		right: 40px;
	`)}
`

const Blob7 = styled(Blob)`
	${fresponsive(css`
		left: 40px;
		top: 900px;
	`)}
`

const Blob8 = styled(Blob)`
	${fresponsive(css`
		right: 40px;
		top: 900px;
	`)}
`
