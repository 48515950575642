import gsap from "gsap"
import { fresponsive } from "library/fullyResponsive"
import { getResponsivePixels } from "library/viewportUtils"
import { useEffect, useRef } from "react"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import { useContext } from "react"
import { CursorContext } from "./Providers/Cursor"

export default function Cursor() {
	const { state } = useContext(CursorContext)
	const cursorRef = useRef<SVGSVGElement | null>(null)

	useEffect(() => {
		switch (state) {
			case "HOVER":
				gsap.to(cursorRef.current, {
					// rotate: 180,
					scale: 1.25,
				})
				break

			default:
				gsap.to(cursorRef.current, {
					scale: 1,
					// rotate: 1,
				})
				break
		}
	}, [state])

	useEffect(() => {
		const mouseMove = (e: MouseEvent) => {
			gsap.set(cursorRef.current, {
				x: e.clientX - getResponsivePixels(20),
				y: e.clientY + getResponsivePixels(2),
				// xPercent: -50,
				// yPercent: -50,
			})
		}

		window.addEventListener("mousemove", mouseMove)

		return () => {
			window.removeEventListener("mousemove", mouseMove)
		}
	})

	return (
		<Wrapper
			ref={cursorRef}
			width="1111"
			height="1513"
			viewBox="0 0 1111 1513"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M504.473 102.339L52.8965 1406.26C35.9258 1455.26 91.0645 1497.69 134.081 1468.73L525.342 1205.3C543.574 1193.03 567.426 1193.03 585.658 1205.3L976.919 1468.73C1019.94 1497.69 1075.07 1455.26 1058.1 1406.26L606.527 102.339C589.752 53.9009 521.249 53.9008 504.473 102.339Z"
				fill="#FE4420"
			/>
			<path
				d="M508.175 335.585L222.768 1144.44C204.133 1197.25 268.301 1240.02 309.873 1202.5L523.044 1010.08C543.732 991.402 575.234 991.539 595.758 1010.39L802.298 1200.13C843.61 1238.08 908.227 1195.68 889.85 1142.67L610.119 335.864C593.382 287.59 525.177 287.403 508.175 335.585Z"
				fill="#FF9B00"
			/>
			<path
				d="M506.653 573.45L396.797 884.525C378.15 937.328 442.297 980.112 483.882 942.607L521.533 908.649C542.217 889.994 573.695 890.132 594.215 908.966L628.29 940.243C669.615 978.174 734.21 935.751 715.821 882.758L608.587 573.729C591.842 525.473 523.662 525.286 506.653 573.45Z"
				fill="white"
			/>
		</Wrapper>
	)

	// return (
	// 	<Wrapper
	// 		width="40"
	// 		height="40"
	// 		viewBox="0 0 40 40"
	// 		fill="none"
	// 		xmlns="http://www.w3.org/2000/svg"
	// 		ref={cursorRef}
	// 	>
	// 		<path
	// 			d="M6.51475 27.8056L7.59672 28.0517C10.5463 28.7224 12.7015 31.0949 12.9382 33.9306C13.6135 42.02 26.2678 42.025 26.9302 33.9346C27.1626 31.0966 29.3171 28.721 32.2684 28.0485L33.5242 27.7623C42.1829 25.7894 42.1492 14.2385 33.4791 12.31L32.4196 12.0743C29.3948 11.4015 27.2024 8.94446 27.0311 6.03545C26.5511 -2.1148 13.7664 -1.95434 12.9939 6.17615C12.7247 9.00956 10.5487 11.3823 7.59499 12.038L6.56004 12.2677C-2.16215 14.2039 -2.19602 25.8248 6.51475 27.8056Z"
	// 			fill="#FE4420"
	// 		/>
	// 		<path
	// 			d="M25.0401 27.8513L25.1908 27.2608C25.6263 25.554 27.1892 24.2956 29.0539 24.1339C34.4288 23.6677 34.5611 16.2359 29.1702 15.9812C27.243 15.8902 25.6105 14.6198 25.1608 12.8613L25.002 12.2401C23.7303 7.26667 16.1731 7.2858 14.9301 12.2656L14.7873 12.8376C14.3455 14.6075 12.7053 15.8884 10.768 15.9766C5.38354 16.2216 5.53074 23.646 10.8973 24.1251C12.7653 24.2919 14.3283 25.5594 14.7549 27.2719L14.8929 27.8256C16.1432 32.8449 23.7608 32.8642 25.0401 27.8513Z"
	// 			fill="#FF9B00"
	// 		/>
	// 		<path
	// 			d="M22.1246 19.5891C22.1246 21.1802 20.7482 22.47 19.0504 22.47C17.3526 22.47 15.9763 21.1802 15.9763 19.5891C15.9763 17.998 17.3526 16.7082 19.0504 16.7082C20.7482 16.7082 22.1246 17.998 22.1246 19.5891Z"
	// 			fill="white"
	// 		/>
	// 		<path
	// 			d="M22.1246 19.5891C22.1246 21.1802 20.7482 22.47 19.0504 22.47C17.3526 22.47 15.9763 21.1802 15.9763 19.5891C15.9763 17.998 17.3526 16.7082 19.0504 16.7082C20.7482 16.7082 22.1246 17.998 22.1246 19.5891Z"
	// 			fill="white"
	// 		/>
	// 	</Wrapper>
	// )
}

const Wrapper = styled.svg`
  position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	pointer-events: none;
	transform-origin: top center;
	rotate: -45deg;

  ${fresponsive(css`
    width: 40px;
    height: 40px;
		filter: drop-shadow(0 0 5px ${colors.black});
  `)}
`

const Wrapper2 = styled(Wrapper)`
	opacity: 0;
`
