import {
	type Dispatch,
	type ReactNode,
	type SetStateAction,
	createContext,
	useState,
} from "react"

type CursorState = "HOVER" | "NONE"

export const CursorContext = createContext<{
	state: CursorState
	setState: Dispatch<SetStateAction<CursorState>>
}>({
	state: "NONE",
	setState: () => false,
})

export function CursorProvider({
	children,
}: {
	children: ReactNode
}) {
	const [state, setState] = useState<CursorState>("NONE")
	console.log(state)

	return (
		<CursorContext.Provider value={{ state, setState }}>
			{children}
		</CursorContext.Provider>
	)
}
