import Background from "images/global/background.png"
import { useBackButton } from "library/Loader/TransitionUtils"
import Scroll from "library/Scroll"
import { fresponsive } from "library/fullyResponsive"
import { useTrackPageReady } from "library/pageReady"
import useTrackFrameTime from "library/useTrackFrameTime"
import styled, { createGlobalStyle, css } from "styled-components"
import { ColorStyle } from "styles/colors"
import textStyles from "styles/text"
import Cursor from "./Cursor"
import Footer from "./Footer"

interface LayoutProps {
	children: React.ReactNode
}

export default function Layout({ children }: LayoutProps) {
	useTrackPageReady()
	useBackButton()
	useTrackFrameTime()

	return (
		<>
			<GlobalStyle />
			<ColorStyle />
			<Cursor />
			<Scroll>
				<Main>
					{children}
					<Footer />
				</Main>
			</Scroll>
		</>
	)
}

const Main = styled.main`
	overflow-x: clip;
	background-image: 
		url(${Background}), 
		url(${Background}), 
		url(${Background}), 
		url(${Background}), 
		url(${Background}), 
		url(${Background}), 
		url(${Background});

	${fresponsive(css`
		background-size: 333px 529px;
		background-position: 
			0 0, 
			260px 264px, 
			520px 528px,
			780px 792px,
			1040px 1056px,
			1300px 1320px,
			1560px 1584px;
		background-repeat: repeat-y, repeat-y, repeat-y;
	`)}
`

// TODO: configure a default text color
const globalCss = css`
	/* default text styles */
	html {
		/* if your project uses a dark color for most text, set that here */
		color: black;
		font-family: sans-serif;
		${textStyles.body}
		cursor: none;
	}

	* {
		/* need this so that fonts match figma */
		text-rendering: geometricprecision;
		-webkit-font-smoothing: antialiased;
	}

	/** restore default focus states for elements that need them */
	*:focus-visible {
		outline: 2px solid #00f8;
	}
`

const GlobalStyle = createGlobalStyle`${globalCss}`
